import "select2/dist/js/select2.full.min";
import "select2/dist/js/i18n/pt-BR";

$(document).ready(function () {
  $(".select2").select2({
    theme: "bootstrap4",
    language: "pt-BR",
  });

  $(".select2AjaxGetClients").select2({
    theme: "bootstrap4",
    language: "pt-BR",
    minimumInputLength: 3,
    maximumInputLength: 20,
    ajax: {
      // url: $('.select2-field').data('endpoint'),
      url: "/api/v1/searches/clients_data.json",
      dataType: "json",
      delay: 250,
      data: function (params) {
        return {
          q: {
            name_or_cpf_or_cnpj_cont: params.term
              .replace(/[^\d]/g, "")
              .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
          },
          page: params.page,
        };
      },
      processResults: function (data) {
        console.log(data);
        return {
          results: $.map(data, function (item) {
            return {
              text: item.name,
              id: item.id,
            };
          }),
        };
      },
    },
  });

  $(".select2AjaxGetAttendances").select2({
    theme: "bootstrap4",
    language: "pt-BR",
    minimumInputLength: 3,
    maximumInputLength: 20,
    ajax: {
      url: "/api/v1/searches/attendances.json",
      dataType: "json",
      delay: 250,
      data: function (params) {
        return {
          q: {
            client_name: params.term,
          },
          page: params.page,
        };
      },
      processResults: function (data) {
        console.log(data);
        return {
          results: $.map(data, function (item) {
            return {
              text: item.name,
              id: item.id,
            };
          }),
        };
      },
    },
  });

  $('.select2-container--bootstrap4').removeAttr('style');
});

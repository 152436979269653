import { domReady } from "../../utils";
import axios from "axios";
import {
  enableSelect,
  disableSelect,
  clearSelectElTarget,
  clearEl,
} from "./concerns";

const main = () => {
  const companyEl = document.querySelector("#company_id_eq");
  const groupEl = document.querySelector("#group_id_eq");
  const dealerEl = document.querySelector("#dealer_id_eq");
  const userEl = document.querySelector("#user_id_eq");

  if (!companyEl && !groupEl && !dealerEl && !userEl) {
    return;
  }

  const BASE_URL = "searches";

  const init = () => {
    if (companyEl) {
      if (companyEl.value === "") {
        clearSelectElTarget(groupEl, "Selecione o Grupo");
        disableSelect(groupEl);
      }
    }

    if (groupEl) {
      if (groupEl.value === "") {
        clearSelectElTarget(dealerEl, "Selecione a Empresa");
        disableSelect(dealerEl);
      }
    }

    if (dealerEl) {
      if (
        Array.from(dealerEl.options).filter((el) => el.value !== "").length > 0
      ) {
        enableSelect(dealerEl);
      } else {
        disableSelect(dealerEl);
        clearSelectElTarget(dealerEl, "Não disponível");
      }
    }

    if (userEl) {
      if (
        Array.from(userEl.options).filter((el) => el.value !== "").length > 0
      ) {
        enableSelect(userEl);
      } else {
        disableSelect(userEl);
        clearSelectElTarget(userEl, "Não disponível");
      }
    }

    companyHandler();
  };

  const companyHandler = () => {
    if (!companyEl) {
      return;
    }

    companyEl.addEventListener("change", function () {
      let company_id = this.value;

      clearSelectElTarget(groupEl, "Selecione o Grupo");
      disableSelect(groupEl);

      clearSelectElTarget(dealerEl, "Selecione a Empresa");
      disableSelect(dealerEl);

      if (userEl) {
        clearSelectElTarget(userEl, "Selecione a Revenda");
        disableSelect(userEl);
      }

      if (company_id === "") {
        clearSelectElTarget(groupEl, "Selecione o Grupo");
        disableSelect(groupEl);
      }

      if (company_id) {
        axios
          // .get(`${BASE_URL}/groups_by_company_id/${company_id}`)
          .get(`${BASE_URL}/groups?q[company_id_eq]=${company_id}`)
          .then(function (response) {
            // Limpando OPTIONS
            clearEl(groupEl);

            // Populando OPTIONS
            if (response.data.groups.length > 0) {
              enableSelect(groupEl);

              groupEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione a Empresa</option>`
              );

              response.data.groups.map((current_data) => {
                groupEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name}</option>`
                );
              });
            }
          });
      } else {
        clearSelectElTarget(groupEl, "Selecione o Grupo");
      }
    });

    groupHandler();
  };

  const groupHandler = () => {
    groupEl.addEventListener("change", function () {
      let group_id = this.value;

      clearSelectElTarget(dealerEl, "Selecione a Empresa");
      disableSelect(dealerEl);

      if (userEl) {
        clearSelectElTarget(userEl, "Selecione a Revenda");
        disableSelect(userEl);
      }

      if (group_id === "") {
        clearSelectElTarget(dealerEl, "Selecione a Empresa");
        disableSelect(dealerEl);
      }

      if (group_id) {
        axios
          // .get(`${BASE_URL}/dealers_by_group_id/${group_id}`)
          .get(`${BASE_URL}/dealers?q[group_id_eq]=${group_id}`)

          .then(function (response) {
            // Limpando OPTIONS
            clearEl(dealerEl);

            // Populando OPTIONS
            if (response.data.dealers.length > 0) {
              enableSelect(dealerEl);

              dealerEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione a Revenda</option>`
              );

              response.data.dealers.map((current_data) => {
                dealerEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name}</option>`
                );
              });
            } else {
              clearSelectElTarget(dealerEl, "Não disponível");
            }
          });
      }
    });
    if (userEl) {
      dealerHandler();
    }
  };

  const dealerHandler = () => {
    dealerEl.addEventListener("change", function () {
      let dealer_id = this.value;

      clearSelectElTarget(userEl, "Selecione a Revenda");
      disableSelect(userEl);

      if (dealer_id === "" && userEl) {
        clearSelectElTarget(userEl, "Selecione a Revenda");
        disableSelect(userEl);
      }

      if (dealer_id) {
        axios
          // .get(`${BASE_URL}/sellers_by_dealer_id/${dealer_id}`)
          // .get(`${BASE_URL}/sellers?q[dealer_id_eq]=${dealer_id}`)
          .get(
            `${BASE_URL}/sellers?q[block_eq]=false&q[dealer_id_eq]=${dealer_id}`
          )
          .then(function (response) {
            // Limpando OPTIONS
            clearEl(userEl);

            // Populando OPTIONS
            if (response.data.sellers.length > 0) {
              enableSelect(userEl);

              userEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione o Vendedor</option>`
              );

              response.data.sellers.map((current_data) => {
                userEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name} (${current_data.dealer})</option>`
                );
              });
            } else {
              clearSelectElTarget(userEl, "Não disponível");
            }
          });
      }
    });
  };

  init();
};

export default domReady(main);

import { domReady } from "../../utils";
import axios from "axios";
import {
  enableSelect,
  disableSelect,
  clearSelectElTarget,
  clearEl
} from "./concerns";

const main = () => {
  const makeEl = document.querySelector("#make_id");
  const modelEl = document.querySelector("#model_id");
  const trimEl = document.querySelector("#trim_id");
  const colorEl = document.querySelector("#color_id");

  if (!makeEl && !modelEl && !trimEl && !colorEl) {
    return;
  }

  const BASE_URL = "searches";

  const init = () => {
    if (makeEl.value === "") {
      clearSelectElTarget(modelEl, "Selecione a Marca");
      disableSelect(modelEl);
    }

    if (modelEl.value === "") {
      clearSelectElTarget(trimEl, "Selecione o Modelo");
      disableSelect(trimEl);
    }

    if ([...trimEl.options].filter(el => el.value !== "").length > 0) {
      enableSelect(trimEl);
    } else {
      disableSelect(trimEl);
      clearSelectElTarget(trimEl, "Não disponível");
    }

    if ([...colorEl.options].filter(el => el.value !== "").length > 0) {
      enableSelect(colorEl);
    } else {
      disableSelect(colorEl);
      clearSelectElTarget(colorEl, "Não disponível");
    }

    makeHandler();
  };

  const makeHandler = () => {
    makeEl.addEventListener("change", function () {
      let make_id = this.value;

      clearSelectElTarget(modelEl, "Selecione a Marca");
      disableSelect(modelEl);

      clearSelectElTarget(trimEl, "Selecione o Modelo");
      disableSelect(trimEl);

      clearSelectElTarget(colorEl, "Selecione o Modelo");
      disableSelect(colorEl);

      if (make_id) {
        axios
          .get(
            // `${BASE_URL}/models?q[type_eq]=New::Model&q[make_id_eq]=${make_id}`
            `${BASE_URL}/models?q[make_id_eq]=${make_id}`
          )
          .then(function (response) {
            // Limpando OPTIONS
            clearEl(modelEl);

            // Populando OPTIONS
            if (response.data.models.length > 0) {
              enableSelect(modelEl);

              modelEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione o Modelo</option>`
              );

              response.data.models.map(current_data => {
                modelEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name}</option>`
                );
              });
            } else {
              clearSelectElTarget(modelEl, "Não disponível");
              clearSelectElTarget(trimEl, "Não disponível");
            }
          });
      } else {
        clearSelectElTarget(modelEl, "Selecione a Marca");
      }
    });
    modelHandler();
  };

  const modelHandler = () => {
    modelEl.addEventListener("change", function () {
      let model_id = this.value;

      clearSelectElTarget(colorEl, "Selecione a Versão");
      disableSelect(colorEl);

      if (model_id) {
        axios
          .get(
            // `${BASE_URL}/trims?q[type_eq]=New::Trim&q[model_id_eq]=${model_id}`
            `${BASE_URL}/trims?q[model_id_eq]=${model_id}`
          )
          .then(function (response) {
            // Limpando OPTIONS
            clearEl(trimEl);

            // Populando OPTIONS
            if (response.data.trims.length > 0) {
              enableSelect(trimEl);

              trimEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione a Versão</option>`
              );

              response.data.trims.map(current_data => {
                trimEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name}</option>`
                );
              });
            } else {
              clearSelectElTarget(trimEl, "Não disponível");
            }
          });
      } else {
        clearSelectElTarget(trimEl, "Selecione o Modelo");
      }
    });

    trimHandler();
  };

  const trimHandler = () => {
    trimEl.addEventListener("change", function () {
      let trim_id = this.value;

      if (trim_id) {
        axios
          // .get(`${BASE_URL}/trim_colors?id=${trim_id}`)
          .get(`${BASE_URL}/colors`)
          .then(function (response) {
            // Limpando OPTIONS
            clearEl(colorEl);

            // Populando OPTIONS
            if (response.data.colors.length > 0) {
              enableSelect(colorEl);

              colorEl.insertAdjacentHTML(
                "beforeend",
                `<option value="">Selecione a Cor</option>`
              );

              response.data.colors.map(current_data => {
                colorEl.insertAdjacentHTML(
                  "beforeend",
                  `<option value="${current_data.id}">${current_data.name}</option>`
                );
              });
            } else {
              clearSelectElTarget(colorEl, "Não disponível");
            }
          });
      } else {
        clearSelectElTarget(colorEl, "Selecione a Versão");
      }
    });
  };

  init();
};

export default domReady(main);
